<template>
  <div>
    <v-row style="margin-top: 50px" v-if="loading">
      <v-col cols="12" class="text-center">
        <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
      </v-col>
    </v-row>

    <v-form v-else v-model="valid" ref="formProduto" lazy-validation>
      <v-container fluid>
        <v-widget title="Produto" :temCampoObrigatorio="true">
          <div slot="widget-content">
            <v-tabs v-model="abaAtiva">
              <v-tab :key="1">Informações Gerais</v-tab>
              <v-tab :key="2" data-cy="aba-impostos">Impostos</v-tab>
            </v-tabs>

            <v-tabs-items v-model="abaAtiva">
              <v-divider></v-divider>

              <v-tab-item :key="1" eager>
                <v-container grid-list-md fluid>
                  <v-row dense>
                    <v-col cols="12" sm="3">
                      <v-text-field autofocus label="Código" v-model="produto.codigo" v-mask="'XXXXXXXXXXXXXXXXXXXX'" data-cy="codigo"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3">
                      <v-text-field label="Código de Barras" v-model="produto.codigo_barra" :rules="validarCodigoBarras" data-cy="codigo_barra"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field label="Descrição *" v-model="produto.descricao" :rules="validarDescricao" required data-cy="descricao"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" v-if="false">
                      <v-select label="Origem *" :items="constantes.ORIGEM_PRODUTO" :rules="validarOrigem" v-model="produto.origem" required data-cy="origem"></v-select>
                    </v-col>
                    <v-col cols="12" sm="3">
                      <buscar-ncm ref="campoNcm" v-model="produto.ncm.codigo" @ncmSelecionado="ncmSelecionado"></buscar-ncm>
                    </v-col>
                    <v-col cols="12" sm="9">
                      <v-text-field label="Descrição do NCM *" v-model="produto.ncm.descricao" :rules="validarNCM" required readonly></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3">
                      <buscar-cest ref="campoCest" v-model="produto.cest.cest" @cestSelecionado="cestSelecionado"></buscar-cest>
                    </v-col>
                    <v-col cols="12" sm="9">
                      <v-text-field label="Descrição do CEST" v-model="produto.cest.descricao" required readonly></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3">
                      <v-select label="Unidade *" ref="campoUnidade" v-model="produto.unidade" :items="constantes.UNIDADES" :rules="validarUnidade" required data-cy="unidade"></v-select>
                    </v-col>
                    <v-col cols="12" sm="3">
                      <v-text-field label="Valor *" :rules="validarValorObrigatorio" v-model="produto.valor" required data-cy="valor"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3">
                      <v-text-field label="Estoque" v-model="produto.estoque" data-cy="estoque"></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-tab-item>

              <v-tab-item :key="2" eager>
                <v-container grid-list-md fluid>
                  <v-row dense v-if="configuracaoTributaria.regime_tributario == 1 || configuracaoTributaria.regime_tributario == 2">
                    <v-col cols="12" sm="10">
                      <v-select label="CSOSN" v-model="produto.csosn" :items="constantes.CSOSN"></v-select>
                    </v-col>
                    <v-col cols="12" sm="2">
                      <v-text-field label="Alíq. ICMS" v-model="produto.aliq_icms" :rules="validarValorObrigatorio"></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row v-else>
                    <!-- ICMS -->
                    <v-col cols="12" sm="12">
                      <span class="title">ICMS</span>
                    </v-col>
                    <v-col cols="12" sm="10">
                      <v-select label="CST *" v-model="produto.cst_icms" :items="constantes.CST_ICMS" data-cy="cst_icms"></v-select>
                    </v-col>
                    <v-col cols="12" sm="2">
                      <v-text-field label="% ICMS *" v-model="produto.aliq_icms" :rules="validarValorObrigatorio" required data-cy="aliq_icms"></v-text-field>
                    </v-col>
                    <v-spacer></v-spacer>

                    <!-- PIS -->
                    <v-col cols="12" sm="12">
                      <span class="title">PIS</span>
                    </v-col>
                    <v-col cols="12" sm="10">
                      <v-select label="CST PIS Entrada *" v-model="produto.cst_pis_entrada" :items="pis" data-cy="cst_pis_ent"></v-select>
                    </v-col>
                    <v-col cols="12" sm="2">
                      <v-text-field label="% PIS Entrada *" v-model="produto.aliq_pis_entrada" :rules="validarValorObrigatorio" data-cy="aliq_pis_ent"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="10">
                      <v-select label="CST PIS Saída *" v-model="produto.cst_pis_saida" :items="pis" data-cy="cst_pis_sai"></v-select>
                    </v-col>
                    <v-col cols="12" sm="2">
                      <v-text-field label="% PIS Saída *" v-model="produto.aliq_pis_saida" :rules="validarValorObrigatorio" data-cy="aliq_pis_sai"></v-text-field>
                    </v-col>
                    <v-spacer></v-spacer>

                    <!-- COFINS -->
                    <v-col cols="12" sm="12">
                      <span class="title">COFINS</span>
                    </v-col>
                    <v-col cols="12" sm="10">
                      <v-select label="CST COFINS Entrada *" v-model="produto.cst_cofins_entrada" :items="cofins" data-cy="cst_cofins_ent"></v-select>
                    </v-col>
                    <v-col cols="12" sm="2">
                      <v-text-field label="% COFINS Entrada *" v-model="produto.aliq_cofins_entrada" :rules="validarValorObrigatorio" required data-cy="aliq_cofins_ent"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="10">
                      <v-select label="CST COFINS Saída *" v-model="produto.cst_cofins_saida" :items="cofins" data-cy="cst_cofins_sai"></v-select>
                    </v-col>
                    <v-col cols="12" sm="2">
                      <v-text-field label="% COFINS Saída *" v-model="produto.aliq_cofins_saida" :rules="validarValorObrigatorio" required data-cy="aliq_cofins_sai"></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-tab-item>
            </v-tabs-items>
          </div>
          <template v-slot:widget-footer-action>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn depressed class="mr-2" @click="cancelar">Cancelar</v-btn>
              <v-btn color="primary" :loading="carregando" @click="salvar()" :disabled="!valid" data-cy="salvar">{{ textoBtnConfirmar }}</v-btn>
            </v-card-actions>
          </template>
        </v-widget>
      </v-container>
    </v-form>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
import constantes from '@/util/constantes';
import validador from '@/util/validador';
import { mask } from 'vue-the-mask';

export default {
  directives: {
    mask,
  },

  data: () => ({
    valid: false,
    validarCodigoBarras: [(v) => !v || (v && validador.codigoBarras(v)) || 'Código de Barras Inválido'],
    validarDescricao: [(v) => !!v || 'Descrição obrigatória', (v) => (v && v.length <= 100) || 'Descrição precisa ter menos do que 100 caracteres'],
    validarOrigem: [(v) => (v === 0 ? true : !!v || 'Origem obrigatória')],
    validarValorObrigatorio: [(v) => (v && v.match('^[0-9]+(\,[0-9]{1,2})?$') !== null) || 'Valor Inválido'],
    validarInteiro: [(v) => !!v || 'Estoque Obrigatório', (v) => (v && v.match('^[0-9]+$') !== null) || 'Valor Inválido'],
    validarNCM: [(v) => !!v || 'NCM Obrigatório'],
    validarCEST: [(v) => !!v || 'CEST Obrigatório'],
    validarDescricaoCest: [(v) => (v && v.length > 0) || 'CEST Inválido'],
    validarUnidade: [(v) => !!v || 'Unidade de Medida Obrigatória'],
    validarAliquota: [(v) => !!v || 'Alíquota Obrigatória'],
    constantes: constantes,
    codigoNcm: '',
    codigoCest: '',
    abaAtiva: 0,
    tiposInformacaoFrete: [
      { text: 'R$', value: 'valor' },
      { text: '%', value: 'percentual' },
    ],
    loading: false,
  }),

  computed: {
    ...mapState('produto', {
      produto: 'itemAtual',
      configuracaoTributaria: 'configuracao_tributaria',
      pis: 'pis',
      cofins: 'cofins',
      carregando: 'carregando',
    }),
    ...mapGetters('produto', {
      estaEditando: 'estaEditando',
      textoBtnConfirmar: 'textoBtnConfirmar',
    }),
  },

  methods: {
    ...mapActions('produto', {
      carregarGrupos: 'carregarGrupos',
      carregarPis: 'carregarPis',
      carregarCofins: 'carregarCofins',
    }),

    ...mapMutations('produto', {
      calcularPrecos: 'calcularPrecos',
    }),

    ncmSelecionado(ncm) {
      this.codigoNcm = ncm.codigo;
      this.$store.commit('produto/setNcm', ncm);
      setTimeout(() => {
        // this.$refs.campoCest.setFocus();
      }, 300);
    },

    cestSelecionado(cest) {
      this.codigoCest = cest.cest;
      this.$store.commit('produto/setCest', cest);
      setTimeout(() => {
        // this.$refs.campoUnidade.setFocus();
      }, 300);
    },

    async salvar() {
      if (this.$refs.formProduto.validate()) {
        try {
          await this.$store.dispatch('produto/salvar');
          this.$router.push('/cadastros/produtos');
        } catch (error) {
          this.$store.commit('setMensagemErro', error);
        }
      }
    },

    cancelar() {
      this.$router.push('/cadastros/produtos');
    },

    carregarConfiguracoesTributarias() {
      this.$store.dispatch('produto/carregarConfiguracoesTributarias');
    },
  },

  async created() {
    try {
      this.loading = true;
      let estaEditando = this.$router.currentRoute.path.indexOf('editar') !== -1 ? true : false;

      if (estaEditando && (!this.produto._id || this.produto._id === '')) {
        this.$store.commit('setMensagemErro', 'Selecione um produto para editar');
        this.$router.push('/cadastros/produtos');
      } else {
        this.carregarConfiguracoesTributarias();
        this.carregarPis();
        this.carregarCofins();
      }

      if (!estaEditando) {
        this.$store.commit('produto/estadoInicial');
      }
    } catch (error) {
      this.$store.commit('setMensagemErro', error);
    } finally {
      this.loading = false;
    }
  },
};
</script>
